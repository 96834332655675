<template>
  <div class="box">
    <div class="title">
      <p>使用说明</p>
    </div>
    <div class="windows">
      <p class="title-item">1 准备图片：<span>在上传细胞器投射电镜图前，请先对图像进行初步处理。</span></p>
      <p>1）获取比例尺的像素长度 </p>
      <p>
        Windows系统：打开电脑中自带的软件【画图】，将图片放入，放大到像素级，按照图片比例尺画线， 左下角会自动出现X轴的像素长度，例如：图片中表示的比例尺的长度则是133像素，代表600nm（6μm）。
      </p>
      <div class="img">
        <img src="../../assets/scaleBar/win.png" alt="">
      </div>
    </div>
    <div class="ios">
      <p>
        MacOS系统：右键图片，打开方式选择【预览】，放大到像素级，直接按照图片比例尺画框，绘制过程中会自动出现X轴和Y轴的像素长度， 例如：图片中表示的比例尺的长度则是133像素，代表600nm（6μm）。
      </p>
      <div class="img">
        <img src="../../assets/scaleBar/ios.png" alt="">
      </div>
    </div>
    <div class="write">
      <p>
        将像素与对应的比例尺长度填入识别系统即可
      </p>
      <p class="img">
        <img src="../../assets/scaleBar/input.png" alt="">
      </p>
      <p>
        2）裁去图像中的无关区域 <br />
        如果你的TEM区域中包含有其他信息，需要非植物细胞区域裁剪。
      </p>
      <p class="img-n">
        <img src="../../assets/scaleBar/00.png" alt="">
      </p>
      <p style="margin-top: 30px">3）保存为小于10 MB的jpg/jpeg图像格式</p>
    </div>
    <div class="title-item title-results">
      2 等待结果
    </div>
    <p class="p">识别过程可能会耗费1-2分钟，识别结果和细胞器参数将会通过邮件发送到您的邮箱。</p>
    <div class="img-upload">
      <img src="../../assets/scaleBar/04.png" alt="">
    </div>
    <div class="title-item title-results">
      3 结果分析
    </div>
    <p class="p">
      结果将包含6部分内容，在以细胞器命名的文件夹中包含了该种类细胞器的分割结果和参数。
      例如，在Chloroplast_info.csv中包含了单个细胞器的面积和电子密度参数，细胞器的个体序号Chloroplast_.png中进行标识。
      在shape_info文件夹中，ShapeResultsTable.csv包含了叶绿体个体的形状信息，
      visibilityGraphs.gpickle包含了细胞器的原始图数据，LabeledShapes.pn包含了细胞器个体序号。
    </p>
    <div class="folder">
      <img src="../../assets/scaleBar/02.png" alt=""/><br/>
      <img src="../../assets/scaleBar/03.png" alt=""/>
    </div>
    <div class="title-item title-results">
      4 开始Plant organ Hunter之旅！
    </div>
    <p class="p">
      您可以在通过此链接获取一些测试图像，通过浏览器打开链接后，单击右键将图像保存到本地。
    </p>
    <p class="p p-4">
      https://github.com/yzy0102/Plantorgan-hunter-OrgSegNet/raw/main/OrgSegNet_LocalDevlopment/test_img/2000nm460pixels.jpg
      <br/>
      https://github.com/yzy0102/Plantorgan-hunter-OrgSegNet/raw/main/OrgSegNet_LocalDevlopment/test_img/2000nm520pixels.jpg
      <br/>
      https://github.com/yzy0102/Plantorgan-hunter-OrgSegNet/raw/main/OrgSegNet_LocalDevlopment/test_img/800nm520pixels.jpg
    </p>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="scss">
.box {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  font-family: Medium;
  padding-top: 73px;

  .p {
    font-size: 18px;
  }

  .title {
    text-align: center;

    p {
      font-size: 18px;
    }
  }

  .title-item {
    color: #295F10;
    font-size: 23px;
    span{
      color: black;
      font-size: 18px;
    }
  }

  .img {
    margin-top: 30px;
    text-align: center;
  }

  .windows {
    margin-top: 60px;
    font-size: 18px;
    line-height: 29px;

    p:nth-child(2) {
      margin-top: 12px;
    }
  }

  .ios {
    margin-top: 30px;
    font-size: 18px;
    line-height: 29px;

    p:nth-child(2) {
      margin-top: 12px;
    }
  }

  .write {
    margin-top: 80px;

    p {
      font-size: 18px;
      line-height: 29px;
    }

    .img-n {
      img {
        width: 100%;
      }
    }

    p:nth-child(3) {
      margin: 30px 0;
    }
  }

  .title-results {
    margin: 30px 0;
  }

  .img-upload {
    margin-top: 60px;

    img {
      width: 100%;
    }
  }

  .folder {
    margin-top: 10px;
  }
  .p-4 {
    margin-top: 20px;
    line-height: 26px;
    padding-bottom: 60px;
  }
}
</style>
